import { useEffect, useMemo, useState } from 'react'
import { compact, sortBy } from 'lodash'
import { ApolloError, useQuery } from '@apollo/client'
import moment, { Moment } from 'moment'

import {
    useReceptionStatusManagerAdminBaseQuery,
    useReceptionStatusManagerOverviewQuery,
    useReceptionStatusBaseQuery,
    useReceptionStatusProvidersQuery,
    useReceptionStatusProviderDelayTimesQuery,
    useReceptionStatusManagerAutoSwipeDelayQuery,
} from '@docpace/reception-status-react-apollo'

import {
    ReceptionStatusProviderFragment,
    AdminDetailFragment,
    PracticeOverviewFragment,
    ManagerOverviewFragment,
} from '@docpace/shared-graphql/fragments'

export type DelaysOutput = {
    providerId: string
    providerMinutesLate: number
}

export type ProviderDelay = {
    providerId: string,
    displayName: string
    providerMinutesLate: number | string
}

interface UseReceptionStatusBaseProps {}

interface UseReceptionStatusBaseOutput {
    practice: PracticeOverviewFragment | null | any // todo fix any
    practiceId: string | null
    providers: ReceptionStatusProviderFragment[] | any[]
    providerIds: string[]
    providerDelayTableData: ProviderDelay[] | null
    manager: ManagerOverviewFragment | null | any // todo fix any
    managerId: string | null
    admin: AdminDetailFragment | null | any // todo fix any
    adminId: string | null
    isLoading: boolean
    hasNetworkError: boolean
    isInitialized: boolean
    isTableDataInitialized: boolean
    lastUpdated: Moment
    autoSwipeDelayTime: number
    refetch: () => Promise<void>
    clearFetchError: () => void
    fetchError: any
}

export const useReceptionStatusBase: (
    inputProps: UseReceptionStatusBaseProps
) => UseReceptionStatusBaseOutput = ({}) => {
    const [hasNetworkError, setHasNetworkError] = useState<boolean>(false)
    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const [isTableDataInitialized, setIsTableDataInitialized] =
        useState<boolean>(false)
    const [fetchError, setFetchError] = useState<ApolloError>()
    const [lastUpdated, setLastUpdated] = useState<Moment>(moment())

    const {
        data: baseData,
        loading: baseIsLoading,
        networkStatus: baseNetworkStatus,
    } = useReceptionStatusBaseQuery({})

    const { practiceId, adminId, managerId } = baseData ?? {}

    const { data: { admin } = {}, loading: adminIsLoading } =
    useReceptionStatusManagerAdminBaseQuery({
        variables: { adminId: adminId ?? '' },
        skip: !adminId,
    })

    const { data: { manager } = {}, loading: managerIsLoading } =
        useReceptionStatusManagerOverviewQuery({
            variables: { managerId: managerId ?? '' },
            skip: !managerId,
        })

    const {
        data,
        loading: practiceIsLoading,
        networkStatus: practiceNetworkStatus,
    } = useReceptionStatusProvidersQuery({
        variables: {
            practiceId: practiceId ?? '',
        },
        skip: !practiceId,
    })

    const practice = data?.practice
    const providers = compact(sortBy(data?.providers?.nodes, 'displayName')) ?? []

    const {
        refetch,
        loading: isLoadingDelays,
        data: receptionStatusProviderDelaysData,
    }= useReceptionStatusProviderDelayTimesQuery(
        {
            variables: {
                practiceId: practiceId ?? '',
            },
            skip: !practiceId || !!fetchError,
            onError: setFetchError,
        }
    )

    const { data: { actors } = {}, loading: swipeDelayLoading } =
        useReceptionStatusManagerAutoSwipeDelayQuery({
            variables: {
                typeBasedActorId: adminId ?? managerId ?? '',
            },
            skip: !adminId || !!managerId,
            onError: setFetchError,
        })

    const isLoading =
        baseIsLoading ||
        practiceIsLoading ||
        isLoadingDelays ||
        swipeDelayLoading ||
        adminIsLoading ||
        managerIsLoading

    useEffect(() => {
        if (baseNetworkStatus > 3 && practiceNetworkStatus > 3 && !baseData) {
            setHasNetworkError(true)
        } else if (
            baseNetworkStatus > 3 &&
            practiceNetworkStatus > 3 &&
            !practice
        ) {
            setHasNetworkError(true)
        } else if (baseNetworkStatus > 3 && practiceNetworkStatus > 3) {
            setHasNetworkError(false)
            setIsInitialized(true)
        } else {
            setHasNetworkError(false)
        }
    }, [
        practiceId,
        !!baseData,
        baseNetworkStatus,
        practiceNetworkStatus,
        isLoading,
    ])

    const delayNodes = receptionStatusProviderDelaysData?.providerDelays?.nodes
    const providerDelayTableData: ProviderDelay[] = useMemo(() => {
        if (providers?.length > -1 && delayNodes) {
            const merged = compact(delayNodes)?.map(({ providerId, ...providerDelay }) => {
                const provider = providers?.find(
                    (p) => {
                        return p?.providerId === providerId
                    }
                )
                return {
                    ...providerDelay,
                    ...provider,
                    providerId: provider?.providerId ?? '',
                    displayName: provider?.displayName ?? '',
                    providerMinutesLate: providerDelay?.['providerMinutesLate'],
                }
            })
            return merged
        } else {
            return []
        }
    }, [providers, delayNodes])

    useEffect(() => {
        if (providerDelayTableData && providerDelayTableData?.length > -1) {
            setIsTableDataInitialized(true)
        }
    }, [baseIsLoading, isInitialized])

    return {
        practice: practice ?? null,
        practiceId: practice?.practiceId ?? null,
        providers,
        providerIds: providers?.map(p=>p?.providerId), 
        providerDelayTableData: providerDelayTableData ?? [],
        manager,
        managerId: managerId ?? null,
        admin,
        adminId: adminId ?? null,
        isLoading,
        hasNetworkError,
        isInitialized,
        isTableDataInitialized,
        lastUpdated,
        autoSwipeDelayTime:
            actors?.nodes?.[0]?.managerStatusSiteAutoSwipeDelaySeconds ?? 7,
        refetch: async () => {
            await refetch()
            setFetchError(undefined)
            setLastUpdated(moment())
        },
        clearFetchError: () => setFetchError(undefined),
        fetchError,
    }
}
