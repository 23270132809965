import React, { FC } from 'react'
import { PracticeOverviewFragment } from '@docpace/shared-graphql/fragments'

interface ReceptionStatusPracticeHeaderProps {
    practice: PracticeOverviewFragment
}

export const ReceptionStatusPracticeHeader: FC<ReceptionStatusPracticeHeaderProps> = ({ practice }) => {

    const practiceNameOverride = typeof window !== 'undefined' ? localStorage.getItem('practiceName') : null
    const practiceName = practiceNameOverride ?? practice?.name

    const textSize =
        practiceName?.length < 20
            ? 'text-4xl lg:text-5xl' // less than 20 characters long
            : practiceName?.length < 28
            ? 'text-3xl lg:text-4xl' // between 20-28
            : 'text-2xl lg:text-3xl' // greather than 28 characters

    return (
        <div className={`w-full px-2 px-6`}>
            <div
                className={`
          max-w-xl lg:max-w-2xl xl:max-w-4xl w-full min-h-18 overflow-hidden
          border-8 border-teal-600 dark:border-docpace-dark border-t-0 shadow-lg rounded-b-3xl
          bg-white dark:bg-base-100 p-2 space-x-4 flex mx-auto flex-col justify-center
        `}
            >
                <div className="flex">
                    <div className="flex-1 flex flex-col">
                        <div className="">
                            <p
                                className={`${textSize} pt-0.5 font-semibold text-center`}
                            >
                                {practiceName}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
