import React, { FC, useEffect, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'

import moment, { Moment } from 'moment'

const logoImage = '/static/2022_ebold_horizontalclock_white.png'

interface ReceptionStatusSiteHeaderProps {
    lastUpdated?: Moment
}

export const ReceptionStatusSiteHeader: FC<ReceptionStatusSiteHeaderProps> = ({
    lastUpdated,
}) => {
    const [currentTime, setCurrentTime] = useState<Moment>(moment())

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(moment())
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [])

    return (
        <div className="w- bg-teal-600 dark:bg-docpace-dark navbar sticky flex justify-center relative shadow-md h-12 lg:h-20 z-[200]">
            <div className="grid grid-cols-3 w-full">
                <div className="flex justify-center">
                    <div className="flex justify-end items-center text-lg md:text-2xl lg:text-3xl text-white">
                        <div className="text-right">
                            {currentTime.format('h:mm')}
                        </div>
                        <div className="relative" style={{ top: -3 }}>
                            :
                        </div>
                        <div className="w-7 md:w-9 lg:w-12">
                            {currentTime.format('ss')}
                        </div>
                        <div className="relative right-1">
                            {currentTime.format('A')}
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <Link href="/" className="text-white no-underline">
                        <img
                            alt=""
                            src={logoImage}
                            draggable={false}
                            className="xs:h-3 sm:h-6 md:h-8 lg:h-12 object-contain select-none"
                        />
                    </Link>
                </div>
                <div className="flex justify-center">
                    <div className="text-sm md:text-md lg:text-2xl text-white text-right">
                        <div className="lg:mb-0.5">Last Updated: </div>
                        <div>{lastUpdated?.fromNow()}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
